import gsap from 'gsap';
import * as THREE from 'three';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { parameters } from './utils.js';

gsap.registerPlugin(ScrollTrigger);

export function setupScrollZoom(camera, cubes) {
    const blocks = [...cubes.children];
    const cameraStartZ = 8;
    const cameraZoomZ = 4;
    let cameraLookAtY = 0

    setTimeout(() => {
        changeCSSClassProperty('vertical-line-1', 'height', '28%')
        changeCSSClassProperty('vertical-line-2', 'height', '57%')
        changeCSSClassProperty('vertical-line-3', 'height', '12%')
    }, 2500);

    setTimeout(() => {
        changeCSSClassProperty('label', 'opacity', 1)
    }, 3000);

    // Capture the initial positions of the blocks
    const initialPositions = blocks.map(block => block.position.y);

    document.querySelectorAll('.section').forEach((section, index) => {
        let startOption = "top center";
        if (index === 0) {
            startOption = "top top";
        }

        gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: startOption,
                end: "bottom center",
                scrub: true,
                markers: false,
                onUpdate: (self) => {
                    const cameraEndHeight = blocks[index].position.y
                    camera.position.z = extendedInterpolateValue(cameraStartZ, cameraZoomZ, self.progress);
                    camera.position.y = extendedInterpolateValue(parameters.cameraStartHeight - (parameters.soilLayers * parameters.blockSpacing), cameraEndHeight, self.progress);
                    cameraLookAtY = extendedInterpolateValue(0, blocks[index].position.y, self.progress)
                    camera.lookAt(new THREE.Vector3(0, cameraLookAtY, 0))

                    const moveDistance = 0.5;

                    // Update Labeling elements
                    if (self.progress >= 0.85 || self.progress <= 0.15)
                        {
                            changeCSSClassProperty("label", "opacity", 1)
                            changeCSSClassProperty('vertical-line-1', 'height', '28%')
                            changeCSSClassProperty('vertical-line-2', 'height', '57%')
                            changeCSSClassProperty('vertical-line-3', 'height', '12%')
                        }
                    else
                        {
                            changeCSSClassProperty("label", "opacity", 0)
                            changeCSSClassProperty('vertical-line-1', 'height', '0%')
                            changeCSSClassProperty('vertical-line-2', 'height', '0%')
                            changeCSSClassProperty('vertical-line-3', 'height', '0%')
                        }

                    // Animate cubes above the current one
                    for (let i = 0; i < index; i++) {
                        const initialPosition = initialPositions[i];
                        blocks[i].position.y = extendedInterpolateValue(initialPosition, initialPosition + moveDistance, self.progress);
                    }

                    // Animate cubes below the current one
                    for (let i = blocks.length - 1; i > index; i--) {
                        const initialPosition = initialPositions[i];
                        blocks[i].position.y = extendedInterpolateValue(initialPosition, initialPosition - moveDistance, self.progress);
                    }

                    // Fade in and out content in block1
                    if (section.id === 'block1') {
                        const content = section.querySelector('.topsoil-container');
                        const fadeInStart = 0.2;
                        const fadeOutStart = 0.8;
                        if (self.progress >= 0.7 || self.progress <= 0.25)
                        {
                            content.style.opacity = 0   
                        }
                        else
                        {
                            content.style.opacity = 1
                        }
                    }
                }
            }
        });
    });
}

function extendedInterpolateValue(startValue, endValue, progress) {
    if (progress <= 0.15) {
        return startValue;  // Short break when zoomed out
    } else if (progress <= 0.3) {
        const lerpProg = Math.min(Math.max((progress - 0.15) * 7, 0), 1);
        return gsap.utils.interpolate(startValue, endValue, lerpProg);  // Zooming in
    } else if (progress <= 0.7) {
        return endValue;  // Stay zoomed in
    } else if (progress <= 0.85) {
        const lerpProg = Math.min(Math.max((progress - 0.7) * 7, 0), 1);
        return gsap.utils.interpolate(endValue, startValue, lerpProg);  // Zooming out
    } else {
        return startValue;  // Short break when zoomed out
    }
}

function changeCSSClassProperty(elementClass, propertyName, propertyValue)
{
    const elements = document.getElementsByClassName(elementClass);
    for (const element of elements)
    {   
        element.style[propertyName] = propertyValue
    }
}
