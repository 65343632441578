import * as THREE from 'three';
import { initGUI } from './gui.js';
import { initScene } from './scene.js';
import { initCamera, updateCameraOnResize } from './camera.js';
import { initRenderer, updateRendererOnResize } from './renderer.js';
import { createSoilProfile } from './soilProfile.js';
import { setupScrollZoom } from './scrollAnimations.js';
import { animate } from './animate.js';

document.addEventListener('DOMContentLoaded', async () => {
    const canvas = document.querySelector('canvas.webgl');
    const scene = initScene();
    const camera = initCamera();
    const renderer = initRenderer(canvas);

    const cubes = await createSoilProfile(scene); // Ensure textures are loaded before creating the profile

    initGUI();

    setupScrollZoom(camera, cubes);

    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    };

    window.addEventListener('resize', () => {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        updateCameraOnResize(camera, sizes);
        updateRendererOnResize(renderer, sizes);
    });

    const clock = new THREE.Clock();
    let isStartOfExperience = true;

    const tick = () => {
        clock.getElapsedTime(); // Keeping this line to avoid possible side effects

        // Animate entry only once at the start of the experience
        if (isStartOfExperience) {
            animate(cubes);
            isStartOfExperience = false;
        }

        // Render the scene
        renderer.render(scene, camera);

        // Request the next frame
        window.requestAnimationFrame(tick);
    };

    // Start the animation loop
    tick();
});
