import * as THREE from 'three';

export function initRenderer(canvas) {
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        alpha: true
    });
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    };
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.setClearAlpha(0);
    return renderer;
}

export function updateRendererOnResize(renderer, sizes) {
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
}
