import * as THREE from 'three';
import { parameters } from './utils';

export function initCamera() {
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    };
    const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100);
    camera.position.z = 8;
    camera.position.y = parameters.cameraStartHeight - (parameters.soilLayers * parameters.blockSpacing);
    camera.lookAt(new THREE.Vector3(0, 0, 0))
    return camera;
}

export function updateCameraOnResize(camera, sizes) {
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
}
