import gsap from 'gsap';
import { parameters } from './utils.js';

// Precompute static values
const { blockSizes, soilHeight, soilLayers } = parameters;
const blockHeights = blockSizes.map(size => (size / 100) * soilHeight);
const totalHeight = blockHeights.reduce((sum, size) => sum + size, 0);
const durations = Array.from({ length: soilLayers }, (_, index) => 1 + (soilLayers - 1 - index) * 0.5);

// Main animation function
export function animate(cubes) {
    const initialDelay = 0.5; // Delay between animations of different blocks

    // Get the initial positions of the cubes
    const targetPositions = cubes.children.map(cube => cube.position.y);

    // Reverse the order of the cubes
    const reverseCubes = cubes.children.slice().reverse();

    reverseCubes.forEach((cube, index) => {
        const initialTargetPosition = targetPositions[soilLayers - 1 - index];
        // Set initial position just outside the view frustum
        cube.position.y = totalHeight / 2 + 5;

        // Animate to the initial target position
        gsap.to(cube.position, {
            y: initialTargetPosition,
            duration: durations[index],
            delay: index * initialDelay, // Animate blocks sequentially
            ease: "power3.out",
            onComplete: () => {
                // Ensure final position is correctly set
                cube.position.y = initialTargetPosition;
            }
        });
    });
}
