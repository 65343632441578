import * as THREE from 'three';

export let cubes;

export function initScene() {
    const scene = new THREE.Scene();
    cubes = new THREE.Group();
    scene.add(cubes);
    return scene;
}
