export const parameters = {
    materialColor: '#ffeded',
    soilLayers: 5,
    soilHeight: 3,
    soilColors: [
        '#6E5D4B',
        '#AA8E76',
        '#A16F3F',
        '#A76B4E',
        '#7F5921'
    ],
    blockSizes: [
        30,
        15,
        20,
        30,
        15
    ],
    soilFullHeight: 110,
    blockSpacing: 0.1,
    cameraStartHeight: 3
};

export function calculateSoilLayerScale(index)
{
    return 1.0 / parameters.soilFullHeight * parameters.blockSizes[index]
}

export function calculateSoilBlockScale(index)
{
    return parameters.soilHeight * calculateSoilLayerScale(index)
}