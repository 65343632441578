import * as THREE from 'three';
import { parameters } from './utils.js';

// Function to create a soil profile and add it to the scene
export async function createSoilProfile(scene) {
    const textures = await loadTextures();

    const cubes = new THREE.Group();
    const blockHeights = parameters.blockSizes.map(size => (size / 100) * parameters.soilHeight);

    blockHeights.forEach((blockHeight, index) => {
        const cubeGeometry = new THREE.BoxGeometry(1, blockHeight, 1);

        const cubeMaterial = new THREE.MeshStandardMaterial({
            color: parameters.soilColors[index],
            map: textures[index],
            emissive: parameters.soilColors[index],
            emissiveMap: textures[index],
            emissiveIntensity: 2,
            //wireframe: true
        });

        const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);

        const previousBlocksHeight = blockHeights.slice(0, index).reduce((sum, size) => sum + size + parameters.blockSpacing, 0);
        cube.position.y = ((parameters.soilHeight + (parameters.soilLayers * parameters.blockSpacing)) / 2) - previousBlocksHeight - (blockHeight / 2);

        cubes.add(cube);
    });

    cubes.rotation.y = Math.PI * 0.25;
    scene.add(cubes);

    
    //DebugPositions(scene, cubes)

    return cubes;
}

async function loadTextures() {
    const textureLoader = new THREE.TextureLoader();
    const texturePaths = [
        'textures/model/Stone_1.jpg',
        'textures/model/Clay_1.jpg',
        'textures/model/Clay_2.jpg',
        'textures/model/Clay_3.jpg',
        'textures/model/Stone_3.jpg'
    ];
    const texturePromises = texturePaths.map(path => new Promise((resolve, reject) => {
        textureLoader.load(path, resolve, undefined, reject);
    }));
    return Promise.all(texturePromises);
}

function DebugPositions(scene, cubes)
{
    const debugGeometry = new THREE.SphereGeometry(0.1, 12, 12)
    const debugMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('yellow')})
    for (let i = 0; i < cubes.children.length; i++)
        {
            const debugMesh = new THREE.Mesh(debugGeometry, debugMaterial)
            debugMesh.position.y = cubes.children[i].position.y
            scene.add(debugMesh)
        }
}
